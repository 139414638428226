<template>
  <div class="big-c">
    <h1 style="text-align: center">Tables</h1>
    <div class="container-fluid list-container">
      <div v-for="table in Tables" :key="'nr' + table.Id" style="">
        <el-button
          :type="table.Busy == '1' ? 'success' : 'info'"
          class="t-btn"
          @click="orderDialog(table)"
          >{{ table.Name }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Nav from "@/pages/frontend/Nav.vue";

export default {
  name: "Table",
  extends: BasePage,
  components: {
    "Nav-bar": Nav,
  },
  data() {
    return {
      Tables: [],
      loadingVisible: false,
    };
  },
  methods: {
    async refresh_info() {
      this.loadingVisible = true;
      const res = await this.post("mese/get_tables");
      this.Tables = res.Tables.sort((a, b) => Number(a.Name) - Number(b.Name));
    },
    async orderDialog(table) {
      if (table.Busy != "1") {
        this.$router.push("/app/order/new" + table.Id);
      } else {
        this.$router.push("/app/order/" + table.Order.Id);
      }
    },
  },
  mounted() {
    // let token = settings.get_token();
    // if(token == null) {
    //   this.$router.push("/").catch(()=>{});
    //   return;
    // }
    this.refresh_info();
  },
};
</script>

<style scoped>
.list-container {
  margin: 0;
  padding: 25px 5px;
  position: absolute;
  height: 95%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: #eff5fb; */
  gap: 10px;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
}

.t-btn {
  width: 15rem;
  height: 15rem;
  margin-bottom: 10px;
}

.big-c {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #eff5fb;
}

@media only screen and (max-width: 600px) {
  /* Pentru telefon: */
  .t-btn {
    width: 10rem;
    height: 10rem;
  }
}
</style>